import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import config from '../../config';
const IndexPage = ({ data }) => {
  return(
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <p>{config.subHeading2}</p>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            Web developer, engineer, musician
            <br />
            Nashville, TN
          </h2>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon solid fas fa-code major style1">
              <span className="label">Web Development</span>
            </span>
          </li>
          <li>
            <span className="icon solid fas fa-volume-up major style2">
              <span className="label">Music</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-user-astronaut major style3">
              <span className="label">Aerospace</span>
            </span>
          </li>
        </ul>
        <p>
            An eclectic collection of experiences, interests and aptitudes
            <br />
            localized in an interesting space-time.
          </p>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section id="dev" className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>
            Work
          </h2>
          <h4>
            Web and Software Development
          </h4>
          <p>
            Builder of ambitious web experiences for over 10 years, focusing on <a href="https://www.drupal.org/u/logickal">Drupal</a> and decoupled content management solutions.
            Read more on my <Link to="/">CV</Link>, or my <Link to="category/development">technical blog posts</Link>.
          </p>
        </div>
      </section>
      <section id="music" className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>
            Music
            <br />
            Production, Composition and Engineering
          </h2>
          <p>
            Visit the home pages for my two main projects, Logickal and The Exotic Ones.  Visit my <a href="https://soundcloud.com/logickal">Soundcloud</a> or my <a href="https://www.discogs.com/artist/1939679-Jeremy-Dickens">Discogs page</a>.
          </p>
        </div>
      </section>
      <section id="other" className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>
            Other Things
            <br />
          </h2>
          <p>
            (in no particular order) Aviation and Aerospace.  Hockey.  History.  <Link to="/category/cooking">Cooking.</Link> Tabletop games.  Simulations.  Travel.  Family.  Various combinations of the above.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>The Latest</h2>
          <p>
            The latest from various sections of the site and my exposure elsewhere on the internet.
            <br />
          </p>
        </header>
        <ul className="features">
          <li className="icon fa-paper-plane">
            <h3>Thoughts Like Wind</h3>            
            <a class="twitter-timeline" data-width="600" data-height="400" data-theme="dark" href="https://twitter.com/logickal?ref_src=twsrc%5Etfw">Tweets by logickal</a>
          </li>
          <li className="icon solid fa-laptop">
            <h3>Technical Items</h3>
            <p>
              The latest project is specifically the work on this blog, which is being constructed with <a href="gatsbyjs.org">Gatsbyjs</a>.
              I will be writing about the process in the Development category of the <Link to="/blog">blog</Link>.
            </p>
          </li>
          <li className="icon solid fa-code">
            <h3>From The Blog</h3>
            {data.allFile.edges.map(({node}) => (
              <blockquote> 
                <em>From { node.childMarkdownRemark.fields.date }: </em><br/>
                { node.childMarkdownRemark.excerpt } 
              <div><Link to={node.childMarkdownRemark.fields.slug}>Read More...</Link></div>
              </blockquote>
              ))}
          </li>
          <li className="icon solid fa-headphones-alt">
            <h3>Latest Music</h3>
            <p>
              <a href="https://logickal.bandcamp.com/album/an-ever-expanding-construct-predicated-by-a-flawed-algorithm">New Logickal EP out on da/73 Imprint</a>.  Recording with the <a href="http://www.theexoticones.com">Exotic Ones.</a>
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Follow Me</h2>
          <p>
            Visit me at some of my other places on the web.
          </p>
        </header>
        
      </div>
      <div class="follow-icons">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      </div>
    </section>
  </Layout>
);
}

export default IndexPage;

export const query = graphql`
query ThoughtsQuery {
  allFile(filter: {sourceInstanceName: {eq: "blog"}, childMarkdownRemark: {frontmatter: {category: {}}}}, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}, limit: 1) {
    edges {
      node {
        childMarkdownRemark {
          id
          excerpt
          frontmatter {
            title
            date(fromNow: true)
            category
          }
          fields {
            slug
            date(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
    totalCount
  }
}
`